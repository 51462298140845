<!--地标富农--产品信息-->
<template>
  <div class="main-div">
    <!--面包屑-->
    <div class="breadTopDiv">
      <el-row class="breadTop">
        <el-col>
          <top-path-list :path-obj="pathObj"></top-path-list>
        </el-col>
      </el-row>
    </div>
    <div class="content-div">
      <el-row>
        <el-col :span="24">
          <el-row>
            <el-col :span="9">
              <!--图片改为轮播图-->
              <el-carousel class="carouselImgs" indicator-position="inside">
                <el-carousel-item v-for="(item,index) in imgCarousel" :key="index">
                  <img :src="item" alt="" class="top-img">
                </el-carousel-item>
              </el-carousel>
              <!--<img alt="" :src="img" style="width: 100%">-->
            </el-col>
            <el-col :span="14" :offset="1" class="textAlignLeft">
              <el-row>
                <el-col :span="24">
                  <div class="infoTitle">{{title}}</div>
                </el-col>
                <!--<el-col :span="10">
                  <img src="~@/assets/img/productLogo1.png" alt="" class="iconClass" v-if="giProductsIsUse == 1">
                  <img src="~@/assets/img/produceLogo2.png" alt="" class="iconClass" v-if="isAssociateMember == 1">
                  <img :src="giProductsLogo" alt="" class="iconClass" style="width: 60px;height: 60px">
                </el-col>-->
              </el-row>
              <!--<div class="infoTitle">{{title}}</div>
              <div>
                <el-tag
                  v-for="tag in tags"
                  :key="tag">
                  {{tag}}
                </el-tag>
              </div>-->
              <div class="giLableClass">
                <!--<el-tag
                  v-for="tag in tags"
                  :key="tag">
                  {{tag}}
                </el-tag>-->
                <!--根据地理标志标签的勾选情况展示图片-->
                <!--地理标志证明/集体商标1-->
                <img src="@/assets/img/giLables/giTrademark.png" alt="" style="margin-right: 10px" v-if="giTrademarkShow">
                <!--农产品3-->
                <img src="@/assets/img/giLables/agricultureProduct.png" alt="" style="margin-right: 10px" v-if="agricultureProductShow">
                <!--非遗8-->
                <img src="@/assets/img/giLables/inculturalHeritage.png" alt="" style="margin-right: 10px" v-if="inculturalHeritageShow">
                <!--地理保护产品2-->
                <img src="@/assets/img/giLables/protectProducts.png" alt="" style="margin-right: 10px" v-if="protectProductsShow">
                <!--驰名商标4-->
                <img src="@/assets/img/giLables/knownTrademark.png" alt="" style="margin-right: 10px" v-if="knownTrademarkShow">
                <!--中欧互认互保7-->
                <img src="@/assets/img/giLables/centralEurope.png" alt="" style="margin-right: 10px" v-if="centralEuropeShow">
                <!--好品山东6-->
                <img src="@/assets/img/giLables/goodProduct.png" alt="" style="margin-right: 10px" v-if="goodProductShow">
                <!--中华老字号5-->
                <img src="@/assets/img/giLables/timehonoredBrand.png" alt="" v-if="timehonoredBrandShow">
              </div>
              <div class="remarkDiv">{{remak}}</div>
              <div style="margin: 5px 0"><img src="@/assets/img/addressIcon.png" alt="" style="margin-right: 10px"><span class="infoValue">{{place}}</span></div>
              <div class="detailBtn cursorPointer" @click="jumpPage" v-if="showDetailBtn">查看详情>></div>
            </el-col>
          </el-row>
        </el-col>
      </el-row>

      <el-row>
        <el-col :span="24">
          <!--<span class="subContent">{{giBrandCode}}</span>
          <span v-if="giBrandCode != '' && giCertificateCode != ''" class="subContent">/</span>
          <span class="subContent">{{giCertificateCode}}</span>
          <span v-if="giCertificateCode != '' && giProtectCode != ''" class="subContent">/</span>
          <span class="subContent">{{giProtectCode}}</span>-->
          <div class="numAndImgClass">
            <!--编号部分-->
            <div class="numClass">
              <!--注册号-->
              <div class="registerNumClass" v-if="giBrandCodeShow">
                <div class="registerNumBtn">
                  <div class="registerNumText">注册号</div>
                </div>
                <div class="registerNumDiv">
                  <div class="registerNumItem" v-for="(item, index) in giBrandCode">
                    {{item}}
                  </div>
                </div>
              </div>
              <!--产品编号-->
              <div class="productNumClass" v-if="giProtectCodeShow">
                <div class="productNumBtn">
                  <div class="productNumText">产品编号</div>
                </div>
                <div class="productNumDiv">
                  <div class="productNumItem" v-for="(item, index) in giProtectCode">
                    {{item}}
                  </div>
                </div>
              </div>
              <!--保护公告号-->
              <div class="protectNumClass" v-if="giCertificateCodeShow">
                <div class="protectNumBtn">
                  <div class="protectNumText">保护公告号</div>
                </div>
                <div class="protectNumDiv">
                  <div class="protectNumItem" v-for="(item, index) in giCertificateCode">
                    {{item}}
                  </div>
                </div>
              </div>
            </div>
            <!--图片部分-->
            <div class="imgClass">
              <img :src="giProductsLogo" alt="" class="iconClass" style="width: 126px;height: 126px">
              <img src="~@/assets/img/productLogoNew1.png" alt="" class="iconClass" v-if="giProductsIsUse == 1">
              <img src="~@/assets/img/productLogoNew2.png" alt="" class="iconClass" v-if="isAssociateMember == 1">
            </div>
          </div>
        </el-col>
      </el-row>

      <el-row>
        <el-col :span="24" class="textAlignLeft">

          <div class="memberCard">
            <div class="unitName">
              <div class="unit-card-name">
                <el-divider direction="vertical"></el-divider>
                <span style="margin-left: 20px"><span class="spanDiv">受保护时间：</span><span class="spanClass">{{giProtectStartTime}} 至 {{giProtectEndTime}}</span></span>
              </div>
            </div>
            <!--<div class="unitName">
              <div class="unit-card-name">
                <el-divider direction="vertical"></el-divider>
                <span style="margin-left: 20px"><span class="spanDiv">保护公文号：</span><span class="spanClass">{{giCertificateCode}}</span></span>
              </div>
            </div>
            <div class="unitName">
              <div class="unit-card-name">
                <el-divider direction="vertical"></el-divider>
                <span style="margin-left: 20px"><span class="spanDiv">证书编号：</span><span class="spanClass">{{giProtectCode}}</span></span>
              </div>
            </div>-->
            <div class="unitName">
              <div class="unit-card-name">
                <el-divider direction="vertical"></el-divider>
                <span style="margin-left: 20px"><span class="spanDiv">登记年份：</span><span class="spanClass">{{giProductsYear.substring(0,10)}}</span></span>
              </div>
            </div>
            <div class="unitName">
              <div class="unit-card-name">
                <el-divider direction="vertical"></el-divider>
                <span style="margin-left: 20px"><span class="spanDiv">证书持有者：</span><span class="spanClass">{{giCertificateOwn}}</span></span>
              </div>
            </div>
            <div class="unitName">
              <div class="unit-card-name">
                <el-divider direction="vertical"></el-divider>
                <span style="margin-left: 20px"><span class="spanDiv">类别：</span><span class="spanClass">{{giProductsType}}</span></span>
              </div>
            </div>
            <div class="unitName">
              <div class="unit-card-name">
                <el-divider direction="vertical"></el-divider>
                <span style="margin-left: 20px"><span class="spanDiv">面积：</span><span class="spanClass">{{giProductsArea}}</span></span>
              </div>
            </div>
            <div class="unitName">
              <div class="unit-card-name">
                <el-divider direction="vertical"></el-divider>
                <span style="margin-left: 20px"><span class="spanDiv">产量：</span><span class="spanClass">{{giProductsYield}}</span></span>
              </div>
            </div>
            <div class="unitName">
              <div class="unit-card-name">
                <el-divider direction="vertical"></el-divider>
                <span style="margin-left: 20px"><span class="spanDiv">经纬度：</span><span class="spanClass">{{giProductsLongitude}}/{{giProductsLatitude}}</span></span>
              </div>
            </div>
            <!--<div class="unitName">
              <div class="unit-card-name">
                <el-divider direction="vertical"></el-divider>
                <span style="margin-left: 20px"><span class="spanDiv">纬度：</span><span class="spanClass">{{giProductsLatitude}}</span></span>
              </div>
            </div>-->
          </div>
          <!--<div class="detailDiv"><span>受保护时间：</span><span>{{giProtectStartTime}} 至 {{giProtectEndTime}}</span></div>
          <div class="detailDiv"><span>保护公文号：</span><span>{{giCertificateCode}}</span></div>
          <div class="detailDiv"><span>证书编号：</span><span>{{giProtectCode}}</span></div>
          <div class="detailDiv"><span>登记年份：</span><span>{{giProductsYear}}</span></div>
          <div class="detailDiv"><span>证书持有者：</span><span>{{giCertificateOwn}}</span></div>
          <div class="detailDiv"><span>类别：</span><span>{{giProductsType}}</span></div>
          <div class="detailDiv"><span>面积：</span><span>{{giProductsArea}}</span></div>
          <div class="detailDiv"><span>产量：</span><span>{{giProductsYield}}</span></div>
          <div class="detailDiv"><span>经度：</span><span>{{giProductsLongitude}}</span></div>
          <div class="detailDiv"><span>纬度：</span><span>{{giProductsLatitude}}</span></div>-->
        </el-col>
        <el-row>
          <el-col :span="24">
            <!--<div class="rowSpaceAround rightDiv" >
              <div>
                &lt;!&ndash;<img src="~@/assets/img/code.jpg" alt="">&ndash;&gt;
                <div id="qrcode" ref="qrcode"></div>
              </div>
              <div>
                <div class="rightFont">更多地标商品</div>
                <div class="rightFont">长按识别二维码</div>
                <div class="rightFont">进入地标商城</div>
              </div>
            </div>-->
            <div class="bottomDiv">
              <div class="qrcodeDiv">
                <img src="@/assets/img/transaction.jpg" alt="" style="padding: 5px;background-color: white;border-radius: 8px" @click="toPath()">
                <!--<div id="qrcode" style="padding: 5px;background-color: white;border-radius: 8px" ref="qrcode" @click="toPath()"></div>-->
              </div>
              <div class="rightFontDiv">
                <div class="rightFontTop">地理标志产品交易中心</div>
                <div class="rightFontBottom">扫描二维码查看更多地标商品</div>
              </div>
            </div>
          </el-col>
        </el-row>
      </el-row>
    </div>
    <!--增加取消下方白条-->
    <div style="height: 20px"></div>
  </div>
</template>

<script>
import QRCode from 'qrcodejs2' //二维码
import topPathList from '@/components/topPathList'
import axios from 'axios'
export default {
  name: 'productInfo',
  components: { topPathList },
  data() {
    return {
      giTrademarkShow: false, //地理标志证明/集体商标1
      agricultureProductShow: false, //农产品3
      inculturalHeritageShow: false, //非遗8
      protectProductsShow: false, //地理保护产品2
      knownTrademarkShow: false, //驰名商标4
      centralEuropeShow: false, //中欧互认互保7
      goodProductShow: false, //好品山东6
      timehonoredBrandShow: false, //中华老字号5
      giBrandCodeShow: false, //注册号是否展示
      giProtectCodeShow: false, //产品编号是否展示
      giCertificateCodeShow: false, //保护公告号是否展示
      pathObj: [
        {
          name: '首页',
          path: '/'
        },
        {
          name: '地标品牌',
          path: ''
        },
        {
          name: '地标产品',
          path: '/richPeasant/index'
        },
        {
          name: '产品信息',
          path: '/richPeasant/productInfo'
        },
      ],
      imgCarousel: [], //产品图片
      title: '', //产品名称
      place: '', //产地
      remak: '',
      tags: [],
      url: "", //接收后台返回的二维码url
      projectId: '',
      giProtectStartTime: '', //受保护开始时间
      giProtectEndTime: '', //受保护结束时间
      giCertificateCode: [], //保护公文号
      giBrandCode: [], //注册号
      giProtectCode: [], //产品编号
      giProductsYear: '', //登记年份
      giCertificateOwn: '', //证书持有者
      giProductsType: '', //产品分类
      giProductsArea: '', //面积
      giProductsYield: '', //产量
      giProductsLongitude: '', //经度
      giProductsLatitude: '', //纬度
      giProductsIsUse: '', //是否GI标志证明产品，1.是，2.否
      isAssociateMember: '', //是否协会会员，1.是，2.否
      giProductsLogo: '', //产品logo
      showDetailBtn: true,

    }
  },
  mounted () {
    //将路由带过来的id存储在session中，用于面包屑跳转
    this.projectId = this.$route.query.id
    sessionStorage.setItem('projectId', JSON.stringify(this.projectId ));
    this.getProjectInfo()
    /*this.$nextTick(() => {
      this.qrcode()
    })*/
  },
  destroyed() {
    document.getElementById('qrcode') ? document.getElementById('qrcode').innerHTML = "" : '';
  },
  methods: {
    //二维码跳转
    toPath() {
      window.open('https://hxdb.zgdbcy.com', '_blank')
    },

    //产品详情查询
    getProjectInfo() {
      this.projectId = this.$route.query.id
      let params = {
        id: this.projectId
      }
      axios({
        method: "get",
        url: process.env.VUE_APP_HUSSAR_DEFAULT_API + "/api/portal/productManage/productDetail/formData",
        params: params
      }).then(res => {
        if (res.data.code === 10000 || res.data.code === 200) {
          //判断是否存在图文描述
          let discription = res.data.data.discription.replace(/<[^<p>]+>/g, '') // 将所有<p>标签 replace ''
            .replace(/<[</p>$]+>/g, '') // 将所有</p>标签 replace ''
            .replace(/&nbsp;/gi, '') // 将所有 空格 replace ''
            .replace(/<[^<br/>]+>/g, '') // 将所有 换行符 replace ''
          if (discription == "") {
            this.showDetailBtn = false
          }

          this.title = res.data.data.giProductsName //产品名称
          this.place = res.data.data.giProductsProducer //产地
          this.remak = res.data.data.remak //描述
          let giProductsPic = res.data.data.giProductsPic
          this.imgCarousel = giProductsPic.split(',') //产品图片
          this.giProtectStartTime = res.data.data.giProtectStartTime //受保护开始时间
          this.giProtectEndTime = res.data.data.giProtectEndTime //受保护结束时间
          this.giProductsYear = res.data.data.giProductsYear //登记年份
          this.giCertificateOwn = res.data.data.giCertificateOwn //证书持有者
          this.giProductsType = res.data.data.giProductsType //产品分类
          this.giProductsArea = res.data.data.giProductsArea //面积
          this.giProductsYield = res.data.data.giProductsYield //产量
          this.giProductsLongitude = res.data.data.giProductsLongitude //经度
          this.giProductsLatitude = res.data.data.giProductsLatitude //纬度
          this.url = res.data.data.productLink //二维码
          this.giProductsIsUse = res.data.data.giProductsIsUse //是否GI标志证明产品，1.是，2.否
          this.isAssociateMember = res.data.data.isAssociateMember //是否协会会员
          this.giProductsLogo = res.data.data.giProductsLogo //商标id
          //标签分隔
          /*let giProductsModelType = res.data.data.giProductsModelType
          let array = giProductsModelType.split(',');
          this.tags = array*/
          /*地标标签*/
          let giProductsModelType = res.data.data.giProductsModelType
          if (giProductsModelType.indexOf("1") != -1) {
            this.giTrademarkShow = true
          }
          if (giProductsModelType.indexOf("2") != -1) {
            this.protectProductsShow = true
          }
          if (giProductsModelType.indexOf("3") != -1) {
            this.agricultureProductShow = true
          }
          if (giProductsModelType.indexOf("4") != -1) {
            this.knownTrademarkShow = true
          }
          if (giProductsModelType.indexOf("5") != -1) {
            this.timehonoredBrandShow = true
          }
          if (giProductsModelType.indexOf("6") != -1) {
            this.goodProductShow = true
          }
          if (giProductsModelType.indexOf("7") != -1) {
            this.centralEuropeShow = true
          }
          if (giProductsModelType.indexOf("8") != -1) {
            this.inculturalHeritageShow = true
          }

          let giBrandCode = res.data.data.giBrandCode //注册号
          if (giBrandCode != "") {
            this.giBrandCodeShow = true
            this.giBrandCode = giBrandCode.split(',');
          } else {
            this.giBrandCodeShow = false
          }
          let giProtectCode = res.data.data.giProtectCode //产品号
          if (giProtectCode != "") {
            this.giProtectCodeShow = true
            this.giProtectCode = giProtectCode.split(',');
          } else {
            this.giProtectCodeShow = false
          }
          let giCertificateCode = res.data.data.giCertificateCode //保护公告号
          if (giCertificateCode != "") {
            this.giCertificateCodeShow = true
            this.giCertificateCode = giCertificateCode.split(',');
          } else {
            this.giCertificateCodeShow = false
          }

          //this.qrcode()

        } else {
          this.$message.warning("请求失败")
        }
      }).catch(err => {
        this.$message.error('请求失败')
      })
    },

    jumpPage() {
      let pathObj = this.pathObj;
      pathObj.push(
        {
          name: '产品详情',
          path: '/richPeasant/productInfoDetail'
        },
      )
      this.$router.push({
        path: '/richPeasant/productInfoDetail',
        query: {
          pathObj: pathObj,
          id: this.projectId
        }
      })
    },
    /*qrcode() {
      let qrcode = new QRCode('qrcode', {
        width: 78, // 设置宽度，单位像素
        height: 78, // 设置高度，单位像素
        text: this.url // 设置二维码内容或跳转地址
      })
    },*/
  }
}
</script>

<style scoped lang="less">
@import '~@/assets/css/common.less';
.main-div {
  background-color: #f8f9fa !important;
}
.breadTopDiv {
  text-align: center;
  .breadTop {
    width: 1200px;
    margin: auto;
  }
}
.content-div {
  margin: auto;
  padding: 0px 20px 0px 20px;
  width: 1160px;
  background: #FFFFFF;

}

.carouselImgs {
  overflow: hidden;
  width: 433px;
  height: 330px;
}
.top-img {
  width: 100%;
  height: 100%;
  border-radius: 8px;
}
.giLableClass {
  display: flex;
  justify-content: flex-start;
}
.numAndImgClass {
  display: flex;
  justify-content: space-between;
}
.numClass {
  display: flex;
}
.registerNumClass {
  width: 178px;
  height: 126px;
  background: linear-gradient(180deg, #A8C8FF 0%, rgba(255,255,255,0) 100%);
  border-radius: 5px;
  .registerNumBtn {
    width: 98px;
    height: 30px;
    background: #6393E4;
    border-radius: 5px;
    position: relative;
    left: 40px;
    top: -15px;
    text-align: center;
    .registerNumText {
      font-size: 16px;
      font-family: SourceHanSansCN-Regular, SourceHanSansCN;
      font-weight: 400;
      color: #FFFFFF;
      line-height: 30px;
    }
  }
  .registerNumDiv {
    text-align: center;
    overflow: auto;
    height: 100px;
    .registerNumItem {
      font-size: 16px;
      font-family: Arial-BoldMT, Arial;
      font-weight: normal;
      color: #27549C;
      line-height: 24px;
    }
  }
}
.productNumClass {
  width: 178px;
  height: 126px;
  background: linear-gradient(180deg, #FFDCAE 0%, rgba(255,255,255,0) 100%);
  border-radius: 5px;
  margin-left: 32px;
  .productNumBtn {
    width: 98px;
    height: 30px;
    background: #E8A46C;
    border-radius: 5px;
    position: relative;
    left: 40px;
    top: -15px;
    text-align: center;
    .productNumText {
      font-size: 16px;
      font-family: SourceHanSansCN-Regular, SourceHanSansCN;
      font-weight: 400;
      color: #FFFFFF;
      line-height: 30px;
    }
  }
  .productNumDiv {
    text-align: center;
    overflow: auto;
    height: 100px;
    .productNumItem {
      font-size: 16px;
      font-family: Arial-BoldMT, Arial;
      font-weight: normal;
      color: #BC5F13;
      line-height: 24px;
    }
  }
}
.protectNumClass {
  width: 178px;
  height: 126px;
  background: linear-gradient(180deg, #BFEADC 0%, rgba(255,255,255,0) 100%);
  border-radius: 5px;
  margin-left: 32px;
  .protectNumBtn {
    width: 98px;
    height: 30px;
    background: #5EB487;
    border-radius: 5px;
    position: relative;
    left: 40px;
    top: -15px;
    text-align: center;
    .protectNumText {
      font-size: 16px;
      font-family: SourceHanSansCN-Regular, SourceHanSansCN;
      font-weight: 400;
      color: #FFFFFF;
      line-height: 30px;
    }
  }
  .protectNumDiv {
    text-align: center;
    overflow: auto;
    height: 100px;
    .protectNumItem {
      font-size: 16px;
      font-family: Arial-BoldMT, Arial;
      font-weight: normal;
      color: #176B3F;
      line-height: 24px;
    }
  }
}
.imgClass {
  float: right;
}

//轮播图指示器设置为圆点
::v-deep .el-carousel__indicator--horizontal .el-carousel__button {
  width: 10px;
  height: 10px;
  background: #dee9f6;
  border: 1px solid #dee9f6;
  border-radius: 50%;
  opacity: 0.5;
}
::v-deep .el-carousel__indicator--horizontal.is-active .el-carousel__button{
  width: 10px;
  height: 10px;
  background: #186CF5;
  border-radius: 50%;
  opacity: 1;
}
::v-deep .el-carousel__indicators--horizontal {
  left: 81% !important;
  top: 80% !important;
}

::v-deep .el-row {
  margin: 10px 0;
}
.infoTitle {
  text-align: left;
  margin: 10px 0 10px 0;
  font-size: 28px;
  font-family: SourceHanSansCN-Bold, SourceHanSansCN;
  font-weight: bold;
  color: #333333;
  line-height: 42px;
}
.infoLabel {
  color: #606266;
}
.remarkDiv {
  font-size: 16px;
  font-family: SourceHanSansCN-Regular, SourceHanSansCN;
  font-weight: 400;
  color: #666666;
  line-height: 29px;
}
.infoValue {
  font-size: 14px;
  font-family: SourceHanSansCN-Regular, SourceHanSansCN;
  font-weight: 400;
  color: #7E91BE;
  line-height: 21px;
}
.detailBtn {
  text-align: right;
  font-size: 14px;
  font-family: SourceHanSansCN-Regular, SourceHanSansCN;
  font-weight: 400;
  color: #186CF5;
  line-height: 21px;
}
.iconLabel {
  font-weight: bold;
  font-size: 20px;
  color: #303133;
  margin: 0 20px 0 0;
}
.iconClass {
  width: 126px;
  margin: 0 10px;
}
.memberCard {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  .unitName {
    height: 50px;
    line-height: 50px;
    width: 49%;
    text-align: left;
    margin-top: 20px;
    background: linear-gradient(270deg, #FFFFFF 0%, #E6F2FF 100%);
    border-radius: 5px;
    //border-left: 3px solid #A9C6F3 ;

    .unit-card-name {
      //margin-left: 20px;
      font-size: 16px;
      font-family: SourceHanSansCN-Regular, SourceHanSansCN;
      font-weight: 400;
      color: #49618A;

      .spanDiv{
        font-size: 14px;
        font-family: SourceHanSansCN-Regular, SourceHanSansCN;
        font-weight: 400;
        color: #49618A;
        line-height: 21px;
      }
      .spanClass {
        font-size: 14px;
        font-family: SourceHanSansCN-Regular, SourceHanSansCN;
        font-weight: 400;
        color: #333333;
        line-height: 21px;
      }

    }
  }
}

.detailDiv {
  margin: 10px 0;
}
.rightFont {
  font-size: 20px;
  margin: 10px 0;
}
.bottomDiv {
  margin-top: 50px;
  height: 150px;
  background-image: url("~@/assets/img/bottomBanner.png") ;
  background-repeat: no-repeat;
  //border-radius: 8px;
  display: flex;
  text-align: center;
  padding-left: 37%;
  padding-top: 4%;
  .qrcodeDiv {
    img {
      width: 80px;
      height: 80px;
    }
  }
  .qrcodeDiv:hover {
    cursor: pointer;
  }
  .rightFontDiv {
    margin-top: 1%;
    margin-left: 3%;
    .rightFontTop {
      font-size: 20px;
      font-family: SourceHanSansCN-Medium, SourceHanSansCN;
      font-weight: 500;
      color: #FFFFFF;
      line-height: 30px;
      text-align: left;
    }
    .rightFontBottom {
      margin-top: 2%;
      font-size: 14px;
      font-family: SourceHanSansCN-Regular, SourceHanSansCN;
      font-weight: 400;
      color: #FFFFFF;
      line-height: 21px;
      text-align: left;
    }

  }
}

.rightDiv {
  width: 50%;
  margin: 2% 25%;
  background: #ecf5ff;
  padding: 20px;
  border-radius: 10px;
}
::v-deep .el-tag {
  margin-right: 20px;
  background: #E9F2FF;
  border-radius: 6px;
}


::v-deep .el-divider {
  width: 3px;
  background-color: #A9C6F3
}

::v-deep .el-divider--vertical {
  margin: 0px;
  height: 25px;
}
.subContent {
  font-size: 18px;
  font-family: SourceHanSansCN-Medium, SourceHanSansCN;
  font-weight: 500;
  color: #333333;
  line-height: 27px;
}
</style>
